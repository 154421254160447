<template>
      <div id="logo">
            <a href="https://easyflat-1892.com/" target="_blank">
                  <img src="../assets/logo_easyflat.png" title="AMB PICOT" alt="AMB PICOT" />
            </a>
      </div>
</template>
<script>
      export default {
            name: 'LogoComponent',
            components: {},
            data() {
                  return {}
            },
            methods: {},
      }
</script>

<style lang="scss">
#logo {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99;
      background-color: white;
      height: 150px;
      display: flex;
      align-items: center;
      padding: 10px;
      img {
            width: 120px;
            height: auto;
            @media screen and (max-width: 600px) {
                  width: 100px;
            }
      }
}
</style>