<template>
	<div class="home">
		<Logo />
		<div class="top">
			<h1>
				<span data-aos="zoom-out-up" data-aos-delay="100">my</span>
				<span data-aos="zoom-out-up" data-aos-delay="200">EasyFlat</span>
			</h1>
			<div class="right">
				<div class="infos" data-aos="zoom-out-up" data-aos-delay="400">
					<div class="nom">{{ user.first_name }} {{ user.last_name }}</div>
					<div class="fonction" v-if="user.custom_fields.societe">{{ user.custom_fields.societe }}</div>
					<div class="deconnexion" @click="deconnexion">Logout</div>
				</div>
				<div class="avatar" data-aos="zoom-out-up" data-aos-delay="500">
					<img v-if="user.avatar" :src="user.avatar" title="" alt="" />
					<img v-else :src="avatar" title="" alt="" />
				</div>
			</div>
		</div>
		<div class="page">
			<div class="gauche" data-aos="fade-up" data-aos-delay="1000">
				<div class="welcome">
					Welcome, <b>{{ user.first_name }}</b>
				</div>
				<div class="sep"></div>
				<div class="reseaux">
					<a href="https://www.linkedin.com/company/easyflat-1892" target="_blank">
						<i class="fab fa-linkedin-in"></i>
					</a>
				</div>
				<p>Use, share and animate EasyFlat social networks</p>
				<div class="sep"></div>
				<a href="https://easyflat-1892.com" target="_blank"><i class="fal fa-angle-right"></i> Visit easyflat-1892.com</a>
			</div>
			<div class="droite" data-aos="fade-up" data-aos-delay="1200">
				<div class="tuiles">
					<a class="tuiles" href="https://configurator.easyflat-1892.com" target="_blank" nofollow>
						<div class="icone"><img src="../assets/icones/configurateur.png" loading="lazy" /></div>
						<div class="title">Configurator</div>
						<div class="description">
							Find the rolling machine that matches your customer's needs.
							<div class="links"></div>
						</div>
						<div class="trait"></div>
					</a>
					<a class="tuiles" href="https://offres-v2.easyflat-1892.com/" target="_blank" nofollow v-if="user.custom_fields.peut_acceder_au_generateur_doffre">
						<div class="icone"><img src="../assets/icones/generator.png" loading="lazy" /></div>
						<div class="title">Offers generator</div>
						<div class="description">
							<div>Simply implement chartered offers for your clients</div>
							<div class="links"></div>
						</div>
						<div class="trait"></div>
					</a>
					<div class="tuiles" href="" target="_blank" nofollow>
						<div class="icone"><img src="../assets/icones/salesguide.png" loading="lazy" /></div>
						<div class="title">Sales guide</div>
						<div class="description">
							<div>A pdf summarizing the sales techniques as well as the details of the options...</div>
							<div class="links">
								<a href="/pdfs/sales_guide_en.pdf" target="_blank">English</a>
								<a href="/pdfs/sales_guide_fr.pdf" target="_blank">Français</a>
							</div>
						</div>
						<div class="trait"></div>
					</div>

					<a class="tuiles" href="https://ambpicot.sharepoint.com/:f:/s/Commercial/EgvcQK7uakVEtDUWLX--lSUBt2-4BtOhK0g53WgXGjFG9A" target="_blank" nofollow>
						<div class="icone"><img src="../assets/icones/data.png" loading="lazy" /></div>
						<div class="title">Files & media</div>
						<div class="description">
							<div>Search and find among Picot resources</div>
							<div class="links"></div>
						</div>
						<div class="trait"></div>
					</a>
				</div>
			</div>
		</div>
		<Bottom />
	</div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import DEFAUTAVATAR from "../assets/favicon.png";
import { mapActions, mapGetters } from "vuex";
import Bottom from "../components/bottom.vue";
import Logo from "../components/logo.vue";
export default {
	name: "HomeView",
	components: { Bottom, Logo },
	data() {
		return {
			avatar: DEFAUTAVATAR,
		};
	},
	computed: {
		...mapGetters({
			user: "user",
		}),
	},
	methods: {
		...mapActions({
			deconnexion: "deconnexion",
		}),
	},
	mounted() {
		AOS.init({
			once: true,
			mirror: false,
		});
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';
.home {
	padding: 0px;
	.top {
		padding: 30px 50px 30px 150px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: $fond;
		gap: 30px;
		@media screen and (max-width: 768px) {
			justify-content: flex-end;
			padding: 30px 15px 30px 115px;
		}
		h1 {
			color: white;
			font-size: 100px;
			line-height: 1;
			font-weight: 500;
			margin: 0;
			display: flex;
			@media screen and (max-width: 980px) {
				font-size: 60px;
			}
			@media screen and (max-width: 768px) {
				display: none;
			}
			span {
				display: block;
			}
		}
		.right {
			display: flex;
			gap: 15px;
			align-items: center;
			justify-content: flex-end;
			.infos {
				text-align: right;
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				justify-content: center;
				.nom {
					font-weight: 600;
					color: black;
					font-size: 16px;
					@media screen and (max-width: 768px) {
						font-size: 13px;
					}
				}
				.fonction {
					font-size: 12px;
					font-weight: 300;
					@media screen and (max-width: 768px) {
						font-size: 11px;
					}
				}
				.deconnexion {
					font-size: 11px;
					padding: 2px 4px;
					background: lighten(black, 90%);
					margin-top: 3px;
					border-radius: 2px;
					transition: all 0.3s ease;
					font-weight: 300;
					cursor: pointer;
					&:hover {
						background: $orange;
						color: white;
					}
					@media screen and (max-width: 768px) {
						font-size: 11px;
					}
				}
			}
			.avatar {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				overflow: hidden;
				border: solid 2px $orange;
				box-shadow: 3px 3px 6px rgba(black, 0.16);
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
	.page {
		background: $fond;
		display: flex;
		padding: 120px 50px 120px 120px;
		@media screen and (max-width: 768px) {
			flex-direction: column;
			padding: 100px 30px 50px 30px;
		}
		.gauche {
			flex: 0 0 260px;
			@media screen and (max-width: 768px) {
				flex: 0 0 100%;
				margin-bottom: 40px;
			}
			.welcome {
				color: $orange;
				font-size: 16px;
				font-weight: 600;
				b {
					display: block;
					font-size: 24px;
				}
			}
			.sep {
				margin: 30px auto 30px -10px;
				height: 2px;
				width: 38px;
				background: $orange;
				@media screen and (max-width: 768px) {
					margin-left: 0;
				}
			}
			p {
				font-weight: 600;
				margin: 20px 0 0;
				color: $texte;
				font-size: 16px;
				padding-right: 70px;
			}
			a {
				font-style: italic;
				font-size: 14px;
				font-weight: 600;
				color: $texte;
				text-decoration: none;
				transition: all 0.2s ease;
				&:hover {
					color: $orange;
				}
			}
			.reseaux {
				display: flex;
				gap: 10px;
				a {
					width: 50px;
					height: 50px;
					background: $orange;
					border-radius: 9px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
					text-decoration: none;
					font-size: 24px;
					transition: all 0.3s ease;
					&:hover {
						background: black;
						transform: translateY(5px);
						filter: drop-shadow(3px 4px 12px rgba(black, 0.3));
					}
				}
			}
		}
		.droite {
			max-width: 1300px;
			margin: 0 auto;
			.tuiles {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 10px;
				@media screen and (max-width: 1300px) {
					grid-template-columns: repeat(2, 1fr);
				}
				@media screen and (max-width: 980px) {
					grid-template-columns: repeat(1, 1fr);
				}
				@media screen and (max-width: 768px) {
					grid-template-columns: repeat(2, 1fr);
				}
				@media screen and (max-width: 600px) {
					grid-template-columns: repeat(1, 1fr);
				}
				.tuiles {
					display: flex;
					flex-direction: column;
					color: white;
					position: relative;
					padding: 30px;
					border-radius: 20px;
					overflow: hidden;
					text-align: center;
					justify-content: center;
					align-items: center;
					text-decoration: none;
					background-size: cover;
					background-position: center;
					box-shadow: 0 3px 6px rgba(black, 0.16);
					transition: all 0.3s ease;
					&:hover {
						box-shadow: 4px 4px 20px rgba(black, 0.5);
						&:before {
							backdrop-filter: blur(4px);
						}
						div.description {
							a {
								display: inline-block;
								font-size: 11px;
								text-decoration: none;
								padding: 5px;
								margin: 20px 5px 0;
								background: rgba(white, 0.2);
								padding: 3px 6px;
								border-radius: 12px;
								color: white;
								transition: all 0.3s ease;
								&:hover {
									background: $orange;
								}
							}
						}
					}
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: rgba(black, 0.5);
						backdrop-filter: blur(0px);
						border-radius: 20px;
						transition: all 0.3s ease;
					}
					div {
						position: relative;
						z-index: 2;
						&.icone {
							width: 60px;
							height: 60px;
							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}
						&.title {
							font-size: 20px;
							margin: 10px auto 0px;
							font-weight: 500;
						}
						&.description {
							font-size: 15px;
							line-height: 1.4;
							.links {
								height: 40px;
							}
							a {
								display: none;
							}
						}
						&.trait {
							width: 40px;
							height: 5px;
							background: $orange;
							margin: 10px;
						}
					}
					&:nth-child(1) {
						background-image: url("../assets/tuiles/configurateur.jpg");
					}
					&:nth-child(2) {
						background-image: url("../assets/tuiles/generateur.jpg");
					}
					&:nth-child(3) {
						background-image: url("../assets/tuiles/salesguide.jpg");
					}
					
					&:nth-child(4) {
						background-image: url("../assets/tuiles/data.jpg");
					}

					&:nth-child(5) {
						background-image: url("../assets/tuiles/agents@2x.jpg");
					}

					&:nth-child(6){
						background-image: url("../assets/tuiles/data.jpg");
					}
				}
			}
		}
	}
}
</style>
